import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'failFish',
  storage: window.localStorage,
});

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    darkTheme: false,
    watchedIds: [],
    timeFilter: 'week',
  },
  mutations: {
    setUserTheme(state, value) {
      state.darkTheme = value;
    },
    addWatchedId(state, value) {
      if (!state.watchedIds.includes(value)) {
        state.watchedIds.push(value);
      }
    },
    setTimeFilter(state, value) {
      state.timeFilter = value;
    },
  },
  actions: {
    setUserTheme(context, value) {
      context.commit('setUserTheme', value);
    },
    addWatchedId(context, value) {
      context.commit('addWatchedId', value);
    },
    setTimeFilter(context, value) {
      context.commit('setTimeFilter', value);
    },
  },
  modules: {
  }
})
