<template>
  <div class="New">
    <VideoGallery v-if="filteredPosts" :items="filteredPosts" :loading="loading"/>
  </div>
</template>

<script>
import VideoGallery from '@/components/VideoGallery.vue';

export default {
  name: 'New',
  metaInfo: {
    title: 'New',
    titleTemplate: 'FailFish | %s',
    meta: [
      { name: 'description', content: 'FailFish - Watch your favorite Twitch Streamers fail! This is the best place for livestream wins, fails and fuck-ups of Twitch Streamers.' },
      { name: 'og:description', content: 'FailFish - Watch your favorite Twitch Streamers fail! This is the best place for livestream wins, fails and fuck-ups of Twitch Streamers.' },
      { property: 'og:title', content: "FailFish - LiveStreamFails"},
      { property: 'og:site_name', content: 'FailFish'},
      {property: 'og:type', content: 'website'},
      {name: 'robots', content: 'index,follow'},
    ],
  },
  components: {
    VideoGallery,
  },
  data: () => {
    return {
      response: [],
      posts: [],
      loading: true,
    };
  },
  async created() {
    await this.fetchPosts();
    this.loading = false;
  },
  methods: {
    async fetchPosts(afterParam) {
      const response = await fetch(
        `https://www.reddit.com/r/livestreamfail/new.json?limit=100${afterParam ? '&after=' + afterParam : ''}`
      );

      const responseJSON = await response.json();
      this.response.push(responseJSON);

      if (responseJSON.data.after && this.response.length < 1) {
        this.fetchPosts(responseJSON.data.after);
        return;
      }

      this.parseResponse();
    },
    parseResponse() {
      this.posts = [];

      for(const item of this.response) {
        this.posts.push(...item.data.children);
      }
    },
  },
  computed: {
    filteredPosts() {
      if (this.posts.length <= 0) {
        return [];
      }

      const filteredPosts = [];
      for(const item of this.posts) {
        if (item.data.secure_media) {
          if (item.data.secure_media.type === 'clips.twitch.tv') {
            filteredPosts.push(item.data);
          }
        }
      }

      return filteredPosts;
    }
  }
}
</script>
