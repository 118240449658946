<template>
  <v-app>
    <div
      class="backend-layout fill-height"
      style="width:100%"
    >

      <v-navigation-drawer
        v-model="drawer"
        app
        touchless
        temporary
      >
        <div>
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            class="ma-2"
            @keydown.enter="routeToSearch()"
          ></v-text-field>
          <div class="button-wrapper mt-10">
            <v-btn to="/new" depressed outlined>
              <v-icon left>
                mdi-new-box
              </v-icon>
              New
            </v-btn>
            <v-btn to="/top" depressed outlined>
              <v-icon left>
                mdi-arrow-up-bold
              </v-icon>
              Top
            </v-btn>
            <v-btn to="/hot" depressed outlined>
              <v-icon left>
                mdi-fire
              </v-icon>
              Hot
            </v-btn>
            <v-btn to="/rising" depressed outlined>
              <v-icon left>
                mdi-elevation-rise
              </v-icon>
              Rising
            </v-btn>
          </div>
          <v-select
            v-model="selectedTimeFilter"
            :items="timeFilter"
            label="Filter by time"
            dense
            hide-details
            flat
            solo-inverted
          ></v-select>
        </div>
      </v-navigation-drawer>

      <v-app-bar
        app
        color="primary"
        dark
        :clipped-left="$vuetify.breakpoint.lgAndUp"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-sm-and-up"/>

        <v-toolbar-title
          class="d-flex align-center ml-0 pl-3"
        >
          <div class="d-sm-none d-md-block">
            <span>
              <router-link :to="{ name: 'Home' }">
                <v-img src="./assets/logo.png" contain height="64" width="64" />
              </router-link>
            </span>
          </div>
          <div class="d-sm-none d-lg-block">FailFish</div>
        </v-toolbar-title>
        <v-spacer class="hidden-sm-and-up"/>
        <div class="control-wrapper">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            @keydown.enter="routeToSearch()"
          ></v-text-field>
          <div class="button-wrapper">
            <v-btn to="/new" depressed outlined>
              <v-icon left>
                mdi-new-box
              </v-icon>
              New
            </v-btn>
            <v-btn to="/top" depressed outlined>
              <v-icon left>
                mdi-arrow-up-bold
              </v-icon>
              Top
            </v-btn>
            <v-btn to="/hot" depressed outlined>
              <v-icon left>
                mdi-fire
              </v-icon>
              Hot
            </v-btn>
            <v-btn to="/rising" depressed outlined>
              <v-icon left>
                mdi-elevation-rise
              </v-icon>
              Rising
            </v-btn>
          </div>
          <v-select
            v-model="selectedTimeFilter"
            :items="timeFilter"
            label="Filter by time"
            dense
            hide-details
            flat
            solo-inverted
            class="ml-2"
            style="max-width: 20%;"
          ></v-select>
        </div>
        <v-btn
          color="transparent"
          depressed
          fab
          @click="changeTheme()"
        >
          <v-icon>mdi-brightness-6</v-icon>
        </v-btn>
      </v-app-bar>

      <v-content
        class="fill-height"
      >
        <v-container
          class="d-flex align-center mt-8 mb-8 justify-center"
          fluid
        >
          <v-row
            align="center"
            justify="center"
            class="px-3 fill-height app-wrapper"
          >
            <v-col
              cols="12"
              class="fill-height"
            >
              <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-content>
    </div>
    <v-footer
      color="grey darken-4"
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          v-for="(link, i) in links"
          :key="i"
          color="white"
          outlined
          class="ma-2"
          :href="link.href"
        >
          {{ link.name }}
        </v-btn>
        <v-col
          class="grey darken-4 py-4 text-center white--text"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>FailFish</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    darkTheme: false,
    search: '',
    drawer: false,
    links: [
      {name: 'Home', href: '/'},
      {name: 'About Us', href: '/about'},
      {name: 'New', href: '/new'},
      {name: 'Top', href: '/top'},
      {name: 'Hot', href: '/hot'},
      {name: 'Rising', href: '/rising'},
    ],
    timeFilter: [
      'hour',
      'day',
      'week',
      'month',
      'year',
      'all',
    ],
    selectedTimeFilter: '',
  }),
  watch: {
    async selectedTimeFilter(value) {
      this.$store.dispatch('setTimeFilter', value);
    }
  },
  mounted() {
    this.selectedTimeFilter = this.$store.state.timeFilter;
  },
  methods: {
    changeTheme() {
      this.darkTheme = !this.darkTheme;
      this.$store.dispatch('setUserTheme', this.darkTheme);
      this.$vuetify.theme.dark = this.darkTheme;
    },
    routeToSearch() {
      this.$router.push({ path: `/search/${this.search}` });
    }
  },
};
</script>

<style lang="scss">
  @import '~vuetify/src/styles/settings/_variables';

  @mixin breakpoint($media){
    @media #{map-get($display-breakpoints, $media)} {
      @content;
    }
  }

  .app-wrapper {
    max-width: 100% !important;
    @include breakpoint('md-and-up') {
      max-width: 70% !important;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    .v-btn {
      margin-right: 0 !important;
      margin-bottom: 8px !important;
    }
    @include breakpoint('sm-and-up') {
      display: block;
      .v-btn {
        margin-right: 8px !important;
        margin-bottom: 0 !important;
      }
    }
  }


  .control-wrapper {
    display: none;
    @include breakpoint('sm-and-up') {
      .v-input {
        margin-right: 16px !important;
        margin-bottom: 0 !important;
      }
      display: flex;
      align-items: center;
      align-content: center;
    }
  }

  .v-toolbar__content {
    // display: flex !important;
    // justify-content: center !important;
    // align-items: center !important;
    @include breakpoint('sm-and-up') {
      justify-content: space-between !important;
    }
  }

  .v-app-bar, .v-toolbar__content {
    height: auto !important;
  }

</style>
