<template>
  <div class="detail fill-height">
    <div v-if="loading" class="fill-height">
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <h2>Loading Fail...</h2>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <div class="loading-container">
            <div class="spinner"></div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else class="fill-height">
      <v-row>
        <v-col cols="12" class="d-flex justify-center" style="padding-top: 56.25%; position: relative; height: 0;">
          <iframe
            v-if="post.url"
            :src="buildTwitchEmbeddedUrl(post.url)"
            height="100%"
            width="60%"
            allowfullscreen="true"
            scrolling="no"
            title="Twitch.tv embed"
            frameborder="0"
            allow="fullscreen"
            style="position: absolute; width: 100%; height: 100%; top: 0;"
          />
        </v-col>
      </v-row>
      <v-row class="pt-3">
        <v-col
          cols="12"
          style="padding: 0;"
        >
          <v-card hover class="rounded-l pa-3">
            <v-row>
              <v-col cols="12">
                <div>
                  <v-card-title>
                    <div class="headline text--primary mr-2">{{ post.title }}</div>
                  </v-card-title>
                  <v-card-actions>
                    <v-chip
                      class="ma-2"
                      label
                      color="transparent"
                    >
                      <v-icon left color="red">
                        mdi-heart
                      </v-icon>
                      {{ post.ups }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      label
                      color="transparent"
                    >
                      <v-icon left>
                        mdi-clock
                      </v-icon>
                      {{ convertDate(post.created_utc) }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      label
                      color="transparent"
                      :href="`https://reddit.com${post.permalink}`"
                      target="_blank"
                    >
                      <v-icon left color="accent">
                        mdi-open-in-new
                      </v-icon>
                      <div class="accent--text">Source</div>
                    </v-chip>
                  </v-card-actions>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12">
          <h1>Comments</h1>
        </v-col>
        <v-col
          cols="12"
          v-for="(comment, i) in comments"
          :key="i"
        >
          <v-card hover class="rounded-l pa-3">
            <v-row dense>
              <v-col cols="12">
                <div>
                  <v-card-title>
                    <div class="body-1 text--primary mr-2">{{ comment.data.author }}</div>
                    <div class="body-1 text--secondary">{{ convertDate(comment.data.created_utc) }}</div>
                  </v-card-title>
                  <v-card-text>
                    <div class="title font-weight-regular text--primary" v-html="decodeHtml(comment.data.body_html)"></div>
                  </v-card-text>
                  <v-card-actions>
                    <v-chip
                      class="ma-2"
                      label
                      color="transparent"
                    >
                      <v-icon left color="red">
                        mdi-heart
                      </v-icon>
                      {{ comment.data.ups }}
                    </v-chip>
                    <v-chip
                      class="ma-2"
                      label
                      color="transparent"
                      v-if="comment.filteredComments.length > 0"
                      @click="showReplies(i)"
                    >
                      <v-icon left color="accent">
                        mdi-comment-text-multiple
                      </v-icon>
                      <div class="accent--text">{{ comment.filteredComments.length }} Replies</div>
                    </v-chip>
                  </v-card-actions>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <div :id="`replySection-${i}`" class="pl-16 d-none">
            <v-card
              hover
              class="rounded-l pa-3 mt-3"
              v-for="(reply, j) in comment.filteredComments"
              :key="j"
            >
              <v-row dense>
                <v-col cols="12">
                  <div>
                    <v-card-title>
                      <div class="body-1 text--primary mr-2">{{ reply.author }}</div>
                      <div class="body-1 text--secondary">{{ reply.createdAt }}</div>
                    </v-card-title>
                    <v-card-text>
                      <div class="title font-weight-regular text--primary" v-html="decodeHtml(reply.html)"></div>
                    </v-card-text>
                    <v-card-actions>
                      <v-chip
                        class="ma-2"
                        label
                        color="transparent"
                      >
                        <v-icon left color="red">
                          mdi-heart
                        </v-icon>
                        {{ reply.ups }}
                      </v-chip>
                    </v-card-actions>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Detail',
  metaInfo() {
    return {
      title: this.post.title,
      titleTemplate: 'FailFish | %s',
      meta: [
        { name: 'description', content: 'FailFish - Watch your favorite Twitch Streamers fail! This is the best place for livestream wins, fails and fuck-ups of Twitch Streamers.' },
        { name: 'og:description', content: 'FailFish - Watch your favorite Twitch Streamers fail! This is the best place for livestream wins, fails and fuck-ups of Twitch Streamers.' },
        { property: 'og:title', content: 'FailFish - LiveStreamFails' },
        { property: 'og:site_name', content: 'FailFish' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: this.post.thumbnail },
        { property: 'og:url', content: `https://failfish.net${this.$router.currentRoute.path}` },
        { name: 'robots', content: 'index,follow' },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: 'FailFish - LiveStreamFails' },
        { name: 'twitter:description', content: 'FailFish - Watch your favorite Twitch Streamers fail! This is the best place for livestream wins, fails and fuck-ups of Twitch Streamers.' },
        { name: 'twitter:image', content: this.post.thumbnail },
      ],
    }
  },
  components: {
  },
  data: () => {
    return {
      loading: true,
      response: [],
      post: {},
      comments: [],
    };
  },
  async created() {
    await this.fetchPost(this.$route.params.id);
    this.loading = false;
    this.$store.dispatch('addWatchedId', this.post.id);
  },
  methods: {
    async fetchPost(id) {
      const response = await fetch(
        `https://www.reddit.com/comments/${id}/.json`
      );

      const responseJSON = await response.json();
      this.response.push(responseJSON);

      this.parseResponse();
    },
    parseResponse() {
      this.post = this.response[0][0].data.children[0].data;
      this.comments = this.response[0][1].data.children;
      this.comments.pop();

      if (this.comments.length >= 0) {
        for (const item of this.comments) {
          const preparedComments = [];
          const replies = (item.data.replies !== undefined) ? item.data.replies.data : undefined;
          if (replies !== undefined && replies.children.length >= 0) {
            for (const childComment of replies.children) {
              const currentComment = childComment;
              if (currentComment.kind === 't1') {
                preparedComments.push({
                  author: currentComment.data.author,
                  createdAt: this.convertDate(currentComment.data.created_utc),
                  html: currentComment.data.body_html,
                  ups: currentComment.data.ups,
                })
              }
            }
          }
          item.filteredComments = preparedComments;
        }
      }
    },
    buildTwitchEmbeddedUrl(clipUrl) {
      const slicedUrl = clipUrl.slice(clipUrl.lastIndexOf('/') + 1);
      return `https://clips.twitch.tv/embed?autoplay=false&clip=${slicedUrl}&parent=failfish.net`
    },
    convertDate(timestamp) {
      const currentDate = moment();
      const uploadedDate = moment.unix(timestamp);
      const difference = moment.duration(currentDate.diff(uploadedDate));

      if (difference.asSeconds() <= 59) {
        return `${difference.asSeconds().toFixed(0)}s ago`;
      }

      if (difference.asMinutes() <= 59) {
        return `${difference.asMinutes().toFixed(0)}m ago`;
      }

      if(difference.asHours() <= 24) {
        return `${difference.asHours().toFixed(0)}h ago`;
      }

      if(difference.asDays() <= 10) {
        return `${difference.asDays().toFixed(0)}d ago`;
      }

      return uploadedDate.format("MM/DD/YYYY");
    },
    decodeHtml(string) {
      const e = document.createElement('textarea');
      e.innerHTML = string;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },
    showReplies(index) {
      const replyElement = document.getElementById(`replySection-${index}`);
      replyElement.classList.toggle('d-none');
    },
  },
}
</script>

<style lang="scss">
.v-card__text {
  margin-bottom: 0;
  padding-bottom: 0;
  p {
    margin-bottom: 0 !important;
  }
}

@keyframes spinner {
  100% {
    transform: rotate(3600deg);
  }
}

.loading-container {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 25%;
  transform: translateY(-50%);
}

.spinner {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: url('https://i.kym-cdn.com/photos/images/newsfeed/000/925/494/218.png_large') center center;
  background-size: contain;
  background-repeat: no-repeat;
  transition-origin: 50% 50%;
  animation: spinner 3s infinite alternate ease-in-out;
}
</style>