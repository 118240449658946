import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: 'G-DW8Y8ZJPH0' }
});

Vue.use(VueMeta, {
  keyName: 'head'
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
