import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue'
import New from '../views/New.vue'
import Top from '../views/Top.vue'
import Hot from '../views/Hot.vue'
import Rising from '../views/Rising.vue'
import Detail from '../views/Detail.vue'
import Search from '../views/Search.vue'
import About from '../views/About.vue'

Vue.use(VueRouter)
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/new',
    name: 'New',
    component: New
  },
  {
    path: '/top',
    name: 'top',
    component: Top
  },
  {
    path: '/hot',
    name: 'Hot',
    component: Hot
  },
  {
    path: '/rising',
    name: 'Rising',
    component: Rising
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/fail/:id',
    name: 'detail',
    component: Detail,
  },
  {
    path: '/search/:query',
    name: 'Search',
    component: Search,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
