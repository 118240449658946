import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import store from '../store';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: store.state.darkTheme,
        themes: {
            light: {
                primary: '#673AB7',
                secondary: '#512DA8',
                accent: '#7E57C2',
            },
            dark: {
                primary: '#673AB7',
                secondary: '#512DA8',
                accent: '#7E57C2',
            },
        },
        },
        icons: {
            iconfont: 'mdi',
        },
});
