<template>
  <div class="about">
    <v-row>
      <v-col cols="12">
        <h1>About us</h1>
        <div class="title">
          FailFish - Watch your favorite Twitch Streamers fail!
          This is the best place for livestream wins, fails and fuck-ups of Twitch Streamers.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h1>DMCA Takedown</h1>
        <p>
          In accordance, failfish.net complies with the Digital Millennium Copyright Act, (Title 17 U.S.C. §512) and World Intellectual Property Organization (WIPO), in responding with all claims that arise out of intellectual property of infringement and regulated under the WIPO Copyright Treaty (WCT).
          We will promptly investigate all notices and remove any alleged infringement, taking appropriate legal actions required under such laws.
        </p>
        <p>
          You may send us an email at info@failfish.net stating the below information:
          <br>
          1. Your contact information (email, name, and/or address) in order to allow the resolution of the complaint filed;
          <br>
          2. The said believed violation of Act No. 618/2003 on Copyright and Rights Related to Copyright, “as amended”; and
          <br>
          3. A written and signed statement stating: “It is my legal intentions with an act of good faith, that I belief the following copyrighted material(s) described herein are not authorized by the owner or legally entitled on behalf of the owner to any third party and therefore is not permitted by law to use the copyrighted material(s) and I swear that the following statement is true and accurate and by law have the exclusive right to bring proceedings of infringement.”
          <br>
          We are not liable for any false claims and you agree that if you have claimed a false claim of copyright infringement with us, that you may be held responsible for all legal actions. Therefore we request that you seek legal advice before reporting any of the following criteria regarding a claim to make sure the copyrighted material is of an infringement.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo: {
    title: 'About',
    titleTemplate: 'FailFish | %s',
    meta: [
      { name: 'description', content: 'FailFish - Watch your favorite Twitch Streamers fail! This is the best place for livestream wins, fails and fuck-ups of Twitch Streamers.' },
      { name: 'og:description', content: 'FailFish - Watch your favorite Twitch Streamers fail! This is the best place for livestream wins, fails and fuck-ups of Twitch Streamers.' },
      { property: 'og:title', content: "FailFish - LiveStreamFails"},
      { property: 'og:site_name', content: 'FailFish'},
      {property: 'og:type', content: 'website'},
      {name: 'robots', content: 'index,follow'},
    ],
  },
  components: {
  },
  data: () => {
    return {
    };
  },
}
</script>
