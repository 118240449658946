<template>
  <div class="video-gallery">
    <v-data-iterator
      :items="items"
      disable-sort
      hide-default-footer
      disablePagination
      :loading="loading"
    >

      <template v-slot:loading>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <h2>Loading Fails</h2>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <div class="loading-container">
              <div class="spinner"></div>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            cols="12"
            lg="3"
            sm="6"
            v-for="(item, i) in props.items"
            :key="i"
          >
            <v-card hover :id="item.id" :href="`/fail/${item.id}`" :class="{ watched: isVideoWatched(item.id) }">
              <div>
                <v-img
                  v-if="item.over_18"
                  src="../assets/nsfw.png"
                  cover
                  aspect-ratio="1.8"
                />
                <v-img
                  v-else-if="item.spoiler"
                  src="../assets/spoiler.png"
                  cover
                  aspect-ratio="1.8"
                />
                <v-img
                  v-else
                  :src="item.secure_media.oembed.thumbnail_url"
                  :lazy-src="item.thumbnail"
                  cover
                  aspect-ratio="1.8"
                />
              </div>
              <v-card-title>
                <div>{{ item.title }}</div>
              </v-card-title>
              <v-card-actions>
                <v-spacer />

                <v-chip
                  class="ma-2"
                  label
                  color="transparent"
                >
                  <v-icon left color="red">
                    mdi-heart
                  </v-icon>
                  {{ item.ups }}
                </v-chip>

                <v-chip
                  class="ma-2"
                  label
                  color="transparent"
                >
                  <v-icon left >
                    mdi-clock
                  </v-icon>
                  {{ convertDate(item.created_utc) }}
                </v-chip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    items: Array,
    loading: Boolean,
  },
  data: () => {
    return {
    };
  },
  methods: {
    convertDate(timestamp) {
      const currentDate = moment();
      const uploadedDate = moment.unix(timestamp);
      const difference = moment.duration(currentDate.diff(uploadedDate));

      if (difference.asSeconds() <= 59) {
        return `${difference.asSeconds().toFixed(0)}s ago`;
      }

      if (difference.asMinutes() <= 59) {
        return `${difference.asMinutes().toFixed(0)}m ago`;
      }

      if(difference.asHours() <= 24) {
        return `${difference.asHours().toFixed(0)}h ago`;
      }

      if(difference.asDays() <= 10) {
        return `${difference.asDays().toFixed(0)}d ago`;
      }

      return uploadedDate.format("MM/DD/YYYY");
    },
    isVideoWatched(id) {
      return this.$store.state.watchedIds.includes(id);
    }
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
};
</script>

<style lang="scss">
.video-gallery {
  .watched {
    opacity: 0.5 !important;
  }
}

@keyframes spinner {
  100% {
    transform: rotate(3600deg);
  }
}

.loading-container {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 25%;
  transform: translateY(-50%);
}

.spinner {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: url('https://i.kym-cdn.com/photos/images/newsfeed/000/925/494/218.png_large') center center;
  background-size: contain;
  background-repeat: no-repeat;
  transition-origin: 50% 50%;
  animation: spinner 3s infinite alternate ease-in-out;
}
</style>